import {
  Auth as MediastoreAuth,
  Register as RegisterComponent,
} from "@cleeng/mediastore-sdk";
import { AuthContext } from "Context/AuthContext";
import React, { useContext } from "react";

const Register = (props) => {
  const { isAuthenticated, setIsAuthenticated } = useContext(AuthContext);

  React.useEffect(() => {
    const loggedIn = MediastoreAuth.isLogged();
    if (loggedIn) {
      MediastoreAuth.logout();
      window.location.reload(false);
    }
  }, []);

  React.useEffect(() => {
    MediastoreAuth.isLogged();
  }, [isAuthenticated]);
  return (
    <div>
      {MediastoreAuth.isLogged() ? (
        <>
          <h2 data-cy="loggedInMessage">Logging out...</h2>
        </>
      ) : (
        <>
          <h1>Register</h1>
          <div id="Sub-Component-Register" hideCaptcha={true}>
            <RegisterComponent onSuccess={setIsAuthenticated} />
          </div>
        </>
      )}
    </div>
  );
};

export default Register;
